<template>
  <section class="full-screen">
    <QrcodeStream @detect="onDetect">
      <img
        @click="closeScanner"
        class="icon-close"
        src="../assets/circle-xmark.svg"
      />
      <div v-if="!ticketDetected" class="helper-container">
        <img class="icon-small" src="../assets/qrcode.svg" />
        <span>Scan the ticket</span>
      </div>

      <b-modal v-else v-model="ticketDetected" :can-cancel="true">
        <TicketTemplate
          :ticket="ticket"
          :selected-pass-data="selectedPassData"
          :qr-code="scannedQrCode"
        />
      </b-modal>

      <!--  <div v-else class="scanned-ticket--info">
        <TicketTemplate :ticket="ticket" :selected-pass-data="selectedPassData"/>
      </div> -->
    </QrcodeStream>
  </section>
</template>

<script>
import TicketTemplate from "./TicketTemplate.vue";
import { QrcodeStream } from "vue-qrcode-reader";
import { mapActions, mapState } from "vuex";
import { ActivitiesStates, ActivitiesActions } from "../store/Storetypes";
import { TicketMixin } from "../mixins";
import { db } from "../firebase";
export default {
  name: "ScanTicket",
  components: {
    QrcodeStream,
    TicketTemplate,
  },
  mixins: [TicketMixin],
  data() {
    return {
      ticketDetected: false,
      selectedPassData: {},
      scannedTicketDetails: {},
      scannedTicket: null,
    };
  },
  mounted() {
    this.getActivities();
  },
  watch: {
    ticket: {
      handler: function () {
        const qrCodeIndex = this.scannedTicket.split("@")[1];
        if (!this.ticket.tickets[qrCodeIndex].isTicketValid) return
        if (this.ticket.tickets[qrCodeIndex].firstTimeRead) {
          const timesRead =
            (this.ticket.tickets[qrCodeIndex].timesRead || 0) + 1;

          this.ticket.tickets[qrCodeIndex] = {
            ...this.ticket.tickets[qrCodeIndex],
            timesRead,
          };
        } else {
          this.ticket.tickets[qrCodeIndex] = {
            ...this.ticket.tickets[qrCodeIndex],
            firstTimeRead: new Date(),
            timesRead: 1,
            expireDate: new Date(
              Date.now() + this.selectedPassData.duration * 60000
            ),
          };
        }

        const id = this.scannedTicket.split("@")[0];
        db.firestore()
          .collection("passes")
          .doc(id)
          .update({ tickets: this.ticket.tickets })
          .then(() => {
            /*  this.$buefy.toast.open({
                duration: 10000,
                message: "Ticket updated!",
                position: "is-bottom",
              }); */
          })
          .catch(() => {
            this.$buefy.toast.open({
              duration: 10000,
              message:
                "There has been an error updating this ticket. Try again later.",
              position: "is-bottom",
              type: "is-danger",
            });
          });
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      activities: (state) => state.activities[ActivitiesStates.ACTIVITIES],
      ticket: (state) => state.activities[ActivitiesStates.TICKET_DATA],
    }),
    scannedQrCode() {
      const index = this.ticket.tickets.findIndex(
        (ticket) => ticket.id === this.scannedTicket
      );
      if (index < 0) {
        return {};
      }
      return this.ticket.tickets[index];
    },
  },
  methods: {
    ...mapActions({
      getActivities: ActivitiesActions.GET_ACTIVITIES,
      retrieveTicketData: ActivitiesActions.GET_TICKET_DATA,
    }),
    closeScanner() {
      if (window.history.length) {
        this.$router.go(-1);
        return;
      }
      this.$router.push({ name: "vendor-dashboard" });
    },
    async onDetect(promise) {
      try {
        const { content } = await promise;

        if (!content) {
          this.notifyError("Couldn't read QR Code");
          return;
        }
        this.scannedTicket = content;
        this.getTicketData(content);
      } catch (error) {
        this.notifyError(error);
      }
    },
    updateTicketData() {
      const qrCodeIndex = this.scannedTicket.split("@")[1];
      if (!this.ticket.tickets[qrCodeIndex]?.firstTimeRead) return;

      if (new Date() > this.ticket.tickets[qrCodeIndex]?.expireDate) {
        this.ticket.tickets[qrCodeIndex] = {
          ...this.ticket.tickets[qrCodeIndex],
          isTicketValid: false,
        };
        const id = this.scannedTicket.split("@")[0];
         db.firestore()
          .collection("passes")
          .doc(id)
          .update({ tickets: this.ticket.tickets })
          .then(() => {
            /*  this.$buefy.toast.open({
                duration: 10000,
                message: "Ticket updated!",
                position: "is-bottom",
              }); */
          })
          .catch(() => {
             this.$buefy.toast.open({
                duration: 10000,
                message:
                  "There has been an error updating this ticket. Try again later.",
                position: "is-bottom",
                type: "is-danger",
              }); 
          });
      }
    },
    getTicketData(ticketData) {
      const ticketId = ticketData.split("@")[0];
      this.retrieveTicketData(ticketId).then(() => {
        const selectedActivityIndex = this.activities.findIndex(
          (activity) => activity.id === this.ticket.selectedPassId
        );
        if (selectedActivityIndex < 0) {
          this.selectedPassData = {};
          return;
        }
        this.selectedPassData = this.activities[selectedActivityIndex];
        if (!this.ticket.tickets[ticketData.split("@")[1]]?.isTicketValid) {
          console.log(this.ticket.tickets);
          this.$buefy.toast.open({
            duration: 7000,
            message: "This ticket has expired",
            position: "is-bottom",
            type: "is-danger",
          });
          return;
        }
        this.ticketDetected = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.full-screen {
  height: 100vh;
}

.icon-close {
  height: 1.75rem;
  float: right;
  margin: 1rem;
  width: auto;
}

.helper-container {
  width: -webkit-fill-available;
  padding: 1rem;
  border-radius: 10px;
  background-color: #00000040;
  color: white;
  margin: 1rem;
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.icon-small {
  height: 1rem;
  width: auto;
  margin-right: 0.5rem;
}

.scanned-ticket--info {
  padding: 1rem;
  bottom: 0;
  position: fixed;
  width: 100%;
}

::v-deep {
  .modal {
    padding: 20px;
    padding-top: 70%;
    .modal-content {
      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
}
</style>
