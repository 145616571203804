<template>
  <div class="ticket-container" v-if="ticket?.ticketId">
    <div class="header-section">
      <img src="../assets/lisbon-pass-logo.svg" />
    </div>

    <ul>
      <div class="mb-5">
        <p class="has-text-weight-bold is-size-5 mb-3 mt-4">
          {{ selectedPassData.title }}
        </p>
        <b-tag type="is-success is-light" size="is-default">{{
          getHoursAndMinutes(selectedPassData.duration)
        }}</b-tag>
      </div>

      <li class="t_row">
        <span class="l-text mr-6" data="Name">{{ ticket.buyerData.name }}</span>
        <span class="r-text" data="No. Of Tickets" v-if="!qrCode?.id">{{
          ticket.tickets.length
        }}</span>
      </li>

      <li class="fi_row">
        <div class="qr-code-section">
        <div v-if="qrCode?.id" class="columns">
          <div class="column qr-code">
            <img class="barcode mt-5" :src="qrCode?.qrCode" />
            <span class="mb-5 mt-2 is-size-6">{{ qrCode?.id }}</span>
          </div>

          <div class="column">
            <p class="has-text-weight-semibold mb-2">Valid until:</p>
            <p class="has-text-weight-light mb-3">
              {{
                qrCode?.expireDate
                  ? formatTimestamp(qrCode?.expireDate)
                  : `${getHoursAndMinutes(
                      selectedPassData.duration
                    )} after the ticket is validated`
              }}
            </p>
            <b-tag
              class="mb-5"
              rounded
              :type="getTagClass(qrCode?.isTicketValid)"
              >{{ qrCode?.isTicketValid ? "Valid" : "Expired" }}</b-tag
            >
          </div>
        </div>

        <section v-else >
          <div class="columns">
            <div class="column qr-code">
              <img class="barcode mt-5" :src="ticket.tickets[0].qrCode" />
              <span class="mb-5 mt-2 is-size-6">{{
                `${ticket.ticketId}@0`
              }}</span>
            </div>
            <div class="column">
              <p class="has-text-weight-semibold mb-2">Valid until:</p>
              <p class="has-text-weight-light mb-3">
                {{
                  ticket.tickets[0]?.expireDate
                    ? formatTimestamp(ticket.tickets[0].expireDate)
                    : `${getHoursAndMinutes(
                        selectedPassData.duration
                      )} after the ticket is validated`
                }}
              </p>
              <b-tag
                class="mb-5"
                rounded
                :type="getTagClass(ticket.tickets[0]?.isTicketValid)"
                >{{
                  ticket.tickets[0]?.isTicketValid ? "Valid" : "Expired"
                }}</b-tag
              >
            </div>
          </div>

          <div v-if="ticket.tickets.length > 1">
            <div v-if="showAllTickets">
              <div
                class="columns"
                v-for="ticketData in ticket.tickets.slice(1)"
                :key="ticketData.id"
              >
                <div class="column qr-code">
                  <img class="barcode" :src="ticketData.qrCode" />
                  <span class="mb-5 mt-2 is-size-6">{{ ticketData.id }}</span>
                </div>

                <div class="column">
                  <p class="has-text-weight-semibold mb-2">Valid until:</p>
                  <p class="has-text-weight-light mb-3">
                    {{
                      ticketData?.expireDate
                        ? formatTimestamp(ticketData?.expireDate)
                        : `${getHoursAndMinutes(
                            selectedPassData.duration
                          )} after the ticket is validated`
                    }}
                  </p>
                  <b-tag
                    class="mb-5"
                    rounded
                    :type="getTagClass(ticketData?.isTicketValid)"
                    >{{
                      ticketData?.isTicketValid ? "Valid" : "Expired"
                    }}</b-tag
                  >
                </div>
              </div>
            </div>

            <div class="button-container mb-5">
              <b-button
              class="mt-3"
              :icon-right="buttonIcon"
              rounded
              type="is-primary is-light"
              @click="showAllTickets = !showAllTickets"
              >{{
                showAllTickets ? "Hide all tickets" : "Show all tickets"
              }}</b-button
            >
            </div>

            
          </div>
        </section>

        <span class="pt-6" v-if="!qrCode?.id"
          >Your passes will only be activated when scanned for the first time.
          Once scanned, pass will expire in
          {{ getHoursAndMinutes(selectedPassData.duration) }}.</span
        >
      </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getHoursAndMinutes, formatTimestamp } from "../helpers";
export default {
  name: "TicketTemplate",
  props: {
    ticket: {
      type: Object,
      default: () => {},
    },
    selectedPassData: {
      type: Object,
      default: () => {},
    },
    qrCode: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      getHoursAndMinutes,
      formatTimestamp,
      showAllTickets: false,
    };
  },

  computed: {
    buttonIcon() {
      return this.showAllTickets ? "chevron-down" : "chevron-up";
    },
  },
  methods: {
    getTagClass(valid) {
      return valid ? "is-success is-light" : "is-danger is-light";
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:400,500,700");

/* Color Variables */
:root {
  --cBackground: #f4f7ff;
  --cHeader: #4985ff;
  --cShadow: #dee5f4;
  --cColor: #3c4454;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.qr-code-section {
  border-top: 0.2em dashed #e1e4e5;
  width: -webkit-fill-available;

  // text-align: -webkit-center;
  span {
    font-size: 14px;
    font-weight: 500;
    color: rgba(14, 61, 77, 0.5);
    text-align: -webkit-center;
  }
  .barcode {
    height: 200px;
  }
  .qr-code {
    display: grid;
    justify-content: center;
  }
  
}

.ticket-container {
  background-color: #fff;
  width: auto;
  max-width: 512px;
  height: auto;
  border-radius: 15px;
  color: #3c4454;
  .button-container{
    display: flex;
    align-items: center;
    width: 100%;
    justify-items: center;
    place-content: center;
  }
}

.header-section {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 62px;
  background: #cef546;
  font-size: 15.5px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  img {
    height: 3rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
}

ul {
  padding: 16px 24px 26px 24px;
  height: calc(100% - 48px);
  list-style-type: none;
  z-index: 999;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  border: 1px solid #dee5f4;
  border-radius: 0 0 15px 15px;
}

li {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

li.f_row {
  font-weight: 500;
  font-size: 44px;
  letter-spacing: 9px;
  padding-bottom: 1px;
}

li.f_row .arrow {
  height: 20px;
  width: 30px;
  color: #989da6;
  opacity: 0.3;
}

li.f_row .san:after,
li.f_row .moscow:after {
  bottom: -10px;
  position: absolute;
  content: attr(data);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #989da6;
}

li.f_row .san:after {
  right: 0;
}

li.f_row .moscow:after {
  left: 0;
}

li.f_row .san {
  width: 96px;
}

li.s_row {
  background: rgba(48, 117, 140, 0.031372549);
  margin-top: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  height: 80px;
  padding: 0px 16px 8px 16px;
}

li.s_row .flight {
  width: 50%;
}

li.s_row .flight,
li.s_row .gate,
li.s_row .seat {
  position: relative;
}

li.s_row .flight:after,
li.s_row .seat:after,
li.s_row .gate:after {
  top: -28px;
  left: 0;
  position: absolute;
  content: attr(data);
  font-weight: 500;
  letter-spacing: 0.2px;
  font-size: 13px;
  color: rgba(14, 61, 77, 0.6117647059);
}

li.t_row {
  overflow: visible;
  width: 100%;
}

li.t_row .l-text,
li.t_row .r-text {
  position: relative;
  width: -webkit-fill-available;
}
li.t_row .r-text {
  position: relative;
  width: -webkit-fill-available;
}

li.t_row .l-text:after,
li.t_row .r-text:after {
  top: -27px;
  position: absolute;
  content: attr(data);
  font-weight: 500;
  letter-spacing: 0.2px;
  font-size: 13px;
  color: rgba(14, 61, 77, 0.6117647059);
}

li.t_row .l-text:after {
  left: 0;
}

li.t_row .r-text:after {
  left: 0;
}

li.fo_row {
  margin-top: 34px;
  text-transform: uppercase;
}

li.fo_row .passenger {
  position: relative;
}

li.fo_row .passenger:after {
  text-transform: none;
  top: -28px;
  left: 0;
  position: absolute;
  content: attr(data);
  font-weight: 500;
  letter-spacing: 0.2px;
  font-size: 13px;
  color: rgba(14, 61, 77, 0.6117647059);
}

li.fi_row {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
}

li.fi_row .barcode {
  fill: #3c4454;
}
</style>
